import React from "react";
import review1 from "./ScreenShots/review1.jpeg";
import review2 from "./ScreenShots/review2.jpeg";
import review3 from "./ScreenShots/review3.jpeg";
import review4 from "./ScreenShots/review4.jpeg";
import review5 from "./ScreenShots/review5.jpeg";
import review6 from "./ScreenShots/review6.jpeg";
import review7 from "./ScreenShots/review7.jpeg";
import review8 from "./ScreenShots/review8.jpeg";
import review9 from "./ScreenShots/review9.jpeg";
import review10 from "./ScreenShots/review10.jpeg";
import './CustomerReviews.css';

const CustomerReviews = () => {
  // Array of imported images
  const reviewImages = [
    review1, review2, review3, review4, review5,
    review6, review7, review8, review9, review10
  ];

  return (
    <div className="p-6 w-4/5 mx-auto customer-reviews" id="customer-reviews-main">
      <h2 className="text-center text-3xl font-bold mb-6 customer-reviews-title" id="customer-reviews">Inspiring Change: Hear from Our Customers!</h2>
      <hr/>
      {/* Scrollable container for reviews */}
      <div
        className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 overflow-y-auto"
        style={{ maxHeight: '400px',marginTop:"40px" }} // Limit height for scrolling
      >
        {reviewImages.map((image, index) => (
          <div
            key={index}
            className="relative overflow-hidden rounded-lg shadow-lg group hover:shadow-xl transform hover:scale-105 transition-all duration-300"
          >
            <img
              src={image}
              alt={`Customer review ${index + 1}`}
              className="w-full h-auto object-contain"
              loading="lazy" // Add lazy loading
              width="300" // Add explicit width (adjust as needed)
              height="200" // Add explicit height (adjust as needed)
            />
            {/* Optional hover overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <p className="text-white text-sm font-semibold">View Full</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerReviews;
