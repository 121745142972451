import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const ShopContext = createContext(null);

const ShopContextProvider = (props) => {
    const [cartItems, setCartItems] = useState([]);
    const [token, setToken] = useState(localStorage.getItem('token'));

    // Fetch cart items from the backend and retrieve token if necessary
    const fetchCartItems = async () => {
        try {
            // Get the token from localStorage or response
            const storedToken = localStorage.getItem('token');
            const currentToken = storedToken; // Use `token` state or `storedToken` from localStorage

            const response = await axios.get('https://jaipurmasale-backend.onrender.com/api/get-cart-items/', {
                headers: currentToken
                    ? { Authorization: `Token ${currentToken}` }
                    : {},
            });

            // If a new token is provided in the response, update both state and localStorage
            // if (response.data.token) {
            //     setToken(response.data.token);
            //     localStorage.setItem('token', response.data.token);
            // }

            setCartItems(response.data.data);
            console.log("Fetched cart items:", response.data.data);
        } catch (error) {
            console.error("Error fetching cart items:", error);
        }
    };

    const addToCart = async (product_pk, size, quantity, offer_price, mrp, is_direct_buy) => {
        try {
            // Determine headers dynamically
            const headers = (token)
            ? { Authorization: `Token ${token}` }
            : {};

            const response = await axios.post(
                'https://jaipurmasale-backend.onrender.com/api/cart-items/',
                {
                    product_pk,
                    size,
                    quantity,
                    offer_price,
                    mrp,
                    is_direct_buy,
                },
                { headers } // Send headers only when needed
            );

            // If a new token is provided in the response, update both state and localStorage
            if (response.data.token) {
                setToken(response.data.token);
                localStorage.setItem('token', response.data.token);
            }

            fetchCartItems(); // Refresh cart items after adding
            console.log("Added to cart:", response.data.data);
        } catch (error) {
            console.error("Error adding to cart:", error);
        }
    };

    useEffect(() => {
        if (token) {
            fetchCartItems();
        }
    }, [token]);

    const removeFromCart = async (product_pk, size, offer_price, mrp, is_direct_buy) => {
        try {
            const config = is_direct_buy
                ? {}
                : {
                      headers: {
                          Authorization: `Token ${token}`,
                      },
                  };

            const response = await axios.post(
                'https://jaipurmasale-backend.onrender.com/api/remove-cart-items/',
                {
                    product_pk,
                    size,
                    offer_price,
                    mrp,
                    is_direct_buy,
                },
                config
            );

            // If a new token is provided in the response, update both state and localStorage
            if (response.data.token) {
                setToken(response.data.token);
                localStorage.setItem('token', response.data.token);
            }

            fetchCartItems(); // Refresh cart items after removal
            console.log("Item removed:", response.data);
        } catch (error) {
            console.error("Error removing item from cart:", error);
        }
    };

    const getTotalCartAmount = () => {
        return cartItems.reduce((total, item) => total + item.quantity * item.offer_price, 0);
    };

    const getTotalCartItems = () => {
        return cartItems.reduce((total, item) => total + item.quantity, 0);
    };

    const clearCartItems = () => {
        setCartItems([]);
    };

    const contextValue = {
        getTotalCartItems,
        getTotalCartAmount,
        cartItems,
        addToCart,
        removeFromCart,
        clearCartItems,
        fetchCartItems,
    };

    return (
        <ShopContext.Provider value={contextValue}>
            {props.children}
        </ShopContext.Provider>
    );
};

export default ShopContextProvider;
