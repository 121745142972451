import React from "react";
import { Helmet } from "react-helmet";
import {
  CustomerServiceOutlined,
  RightOutlined,
  SafetyCertificateFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Typography } from "antd";
import "./Query.css";
import { Link } from "react-router-dom";

export default function Query() {
  return (
    <div className="query-page-main">
      <Helmet>
        <link rel="canonical" href="https://www.jaipurmasale.com/query" />
      </Helmet>
      <h2 className="query-title">Why Switch to Jaipur Masale & Dry Fruits?</h2>
      <hr />
      <div className="query-description">
        <Row>
          <Col lg={1} xs={2}></Col>
          <Col lg={22} xs={20}>
            <Row>
              <Col lg={11} xs={24}>
                <div>
                  {/* <Typography className="query-section-title">
                    Why Switch to Jaipur Masale & Dry Fruits?
                  </Typography> */}
                  <Typography className="query-text">
                    If you're still reading this, we are pretty sure you already
                    recognize the benefit of buying from us. However, if you are
                    still not convinced, here's a few extra points to change your
                    mind
                  </Typography>
                  <div className="listed-text">
                    <ul>
                      <li>FDA Approved </li>
                      <li>100% Natural Ingredients</li>
                      <li>Premium Quality Spices & Dry Fruits</li>
                      <li>Trusted and adored by households across India</li>
                      <li>Crafted with Heritage</li>
                      <li>Freshly Grinded</li>
                      <li>Healthier Living</li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={4} xs={4}></Col>
              <Col lg={9} xs={22}>
                <div style={{ display: "flex", flexDirection: "column", gap: 25 }}>
                  <div>
                    <Card>
                      <div className="rightside-card">
                        <div className="rightside-icon">
                          <SafetyCertificateFilled
                            style={{ color: "#5e5ee9", fontSize: 26 }}
                          />
                        </div>
                        <div>
                          <Link to="organic-khada-masala">
                            <Typography.Title level={3} className="rightside-card-title">
                              Buy Khada Masala
                            </Typography.Title>
                            <Typography className="card-description">
                              Add a burst of authentic flavor to your dishes with our premium Khada Masala.
                            </Typography>
                          </Link>
                        </div>
                        <div className="rightside-secoend-icon">
                          <RightOutlined style={{ fontSize: 26 }} />
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div>
                    <Card>
                      <div className="rightside-card">
                        <div className="rightside-icon">
                          <SafetyCertificateFilled
                            style={{ color: "#5e5ee9", fontSize: 26 }}
                          />
                        </div>
                        <div>
                          <Link to="/organic-powdered-masala">
                            <Typography.Title level={3} className="rightside-card-title">
                              Buy Powdered Masala
                            </Typography.Title>
                            <Typography className="card-description">
                              Bring the essence of authentic Indian flavors to your cooking with our expertly blended powdered masalas.
                            </Typography>
                          </Link>
                        </div>
                        <div className="rightside-secoend-icon">
                          <RightOutlined style={{ fontSize: 26 }} />
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div>
                    <Card>
                      <div className="rightside-card">
                        <div className="rightside-icon">
                          <SafetyCertificateFilled
                            style={{ color: "#5e5ee9", fontSize: 26 }}
                          />
                        </div>
                        <div>
                          <Link to="/premium-dry-fruits-berries">
                            <Typography.Title level={3} className="rightside-card-title">
                              Buy Dry Fruits & Berries
                            </Typography.Title>
                            <Typography className="card-description">
                              Indulge in the wholesome goodness of our premium dry fruits, berries & seeds.
                            </Typography>
                          </Link>
                        </div>
                        <div className="rightside-secoend-icon">
                          <RightOutlined style={{ fontSize: 26 }} />
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={1} xs={2}></Col>
        </Row>
      </div>
    </div>
  );
}
