import React, { useContext, useRef, useState, useEffect } from 'react';
import './Navbar.css';
import cart_icon from '../Assets/cart_icon.webp';
import nav_dropdown from '../Assets/nav_dropdown.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import { ShopContext } from '../../Context/ShopContext';
import { useNavigate } from 'react-router';
import jaipur_masale from '../Assets/jaipur_masale.webp';
import { FaSearch } from 'react-icons/fa';
import Overlay from '../Overlay';

const Navbar = ({ isHomePage }) => {
  const [menu, setMenu] = useState("shop");
  const { clearCartItems, cartItems, getTotalCartItems } = useContext(ShopContext);
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  // const [scrollUp, setScrollUp] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const menuRef = useRef();
  const imageRef = useRef();
  const navigate = useNavigate();
  const lastScrollY = useRef(window.scrollY);

  const dropdownToggle = (e) => {
    menuRef.current.classList.toggle('nav-menu-visible');
    e.target.classList.toggle('open');
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setIsAuthenticated(!!localStorage.getItem('token'));
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // useEffect(() => {
  //   if(scrollUp) {
  //     console.log('dropdownOpen', dropdownOpen);
  //     setDropdownOpen(false);
  //   }
  // }, [scrollUp])

  useEffect(() => {
    getTotalCartItems();
  }, [cartItems, getTotalCartItems]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    clearCartItems();
    navigate('/');
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const scrollPosition = currentScrollY <= lastScrollY.current || currentScrollY <= 100;
      // setScrollUp(scrollPosition);
      if(scrollPosition) {
        setDropdownOpen(false);
        menuRef.current.classList.remove('nav-menu-visible');
      }
      lastScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?query=${searchQuery}`);
  };

  return (
    <>
    <div className={`navbar visible' ${isHomePage ? 'home' : 'not-home'}`}>
      <div className='navbar-horizontal'>
      <Link to='/' onClick={() => setMenu("shop")} className="nav-logo">
        <img src={jaipur_masale} alt="Logo" />
      </Link>
      <img onClick={dropdownToggle} className='nav-dropdown' src={nav_dropdown} alt="Menu" ref={imageRef}/>

      <ul ref={menuRef} className="nav-menu">
        <li onClick={(e) => { setMenu("Home");  setDropdownOpen(false); menuRef.current.classList.remove('nav-menu-visible'); imageRef.current.classList.remove('open'); }}>
          <Link to='/'>Home</Link>
          {menu === "Home" && <hr />}
        </li>
        <li onClick={(e) => { setMenu("organic_khada_masala"); setDropdownOpen(false); menuRef.current.classList.remove('nav-menu-visible'); imageRef.current.classList.remove('open'); }}>
          <Link to="/organic-khada-masala">Khada Masala</Link>
          {menu === "organic_khada_masala" && <hr />}
        </li>
        <li onClick={(e) => { setMenu("organic_powdered_masala"); setDropdownOpen(false); menuRef.current.classList.remove('nav-menu-visible'); imageRef.current.classList.remove('open'); }}>
          <Link to='/organic-powdered-masala'>Powdered Masala</Link>
          {menu === "organic_powdered_masala" && <hr />}
        </li>
        <li onClick={(e) => { setMenu("premium_dry_fruits_seeds"); setDropdownOpen(false); menuRef.current.classList.remove('nav-menu-visible'); imageRef.current.classList.remove('open'); }}>
          <Link to='/premium-dry-fruits-berries'>Dry Fruits & Berries</Link>
          {menu === "premium_dry_fruits_seeds" && <hr />}
        </li>
        <li onClick={(e) => { setMenu("Blog"); setDropdownOpen(false); menuRef.current.classList.remove('nav-menu-visible'); imageRef.current.classList.remove('open'); }}>
          <Link to='/Blog'>Blog</Link>
          {menu === "Blog" && <hr />}
        </li>
        <li onClick={(e) => { setMenu("contact_us"); setDropdownOpen(false); menuRef.current.classList.remove('nav-menu-visible'); imageRef.current.classList.remove('open'); }}>
          <Link to='/contact-us'>Contact Us</Link>
          {menu === "contact_us" && <hr />}
        </li>
        <form onSubmit={handleSearchSubmit} className="nav-search-form-desktop">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="nav-search-input"
          />
          <button type="submit" className="nav-search-button">
            <FaSearch />
          </button>
        </form>
      </ul>

      <div className="nav-login-cart">
        {!isAuthenticated ? (
          <Link to='/Login'><button>Login</button></Link>
        ) : (
          <div className="profile-dropdown">
            <FontAwesomeIcon icon={faUser} style={{ color: "#000", fontSize: '24px' }} />
            <div className="dropdown-content">
              <button onClick={handleLogout}>Logout</button>
            </div>
          </div>
        )}
        <Link to='/cart'><img src={cart_icon} alt="Cart" /></Link>
        <div className="nav-cart-count">{getTotalCartItems()}</div>
      </div>
      </div>

      <form onSubmit={handleSearchSubmit} className="nav-search-form">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="nav-search-input"
        />
        <button type="submit" className="nav-search-button">
          <FaSearch />
        </button>
      </form>
    </div>
    {dropdownOpen && <Overlay open={dropdownOpen}/>}
    </>
  );
};

export default Navbar;
